<template>
    <div class="inner_pages" id="checkout_index" v-show="user && user.id">
        <sidebar-component v-model="showSidebar"/>
        <div class="container">
            <div class="respective_content">
                <div class="section_header mb-4">
                    <button type="button" class="bar_menu" @click="showSidebar = !showSidebar"><img src="@/assets/images/bar_menu.svg"></button>
                    <h2>Dashboard</h2>
                    <div class="tutorial_btn" @click="tutorial = true;"><label>Tutorials</label><img src="@/assets/images/play.svg"></div>
                    <!-- <button type="button" class="video_btn" @click="tutorial = true;"><i class="fas fa-play"></i></button> -->
                </div>
                <div class="checkout-dashboard">
                    <div class="panel_wpr">
                        <div class="actions_widgets">
                            <div class="left_sectn">
                                <div class="widgets_wpr">
                                    <div class="loader_area4" v-if="chartLoader"><quote-loader/></div>
                                    <div class="header">
                                        <ul class="tabs">
                                            <li :class="{'active' : charTabs === 'total'}" @click="charTabs = 'total'; filter.pricing_type = 'all'">Total</li>
                                            <li :class="{'active' : charTabs === 'one-time'}" @click="charTabs = 'one-time'; filter.pricing_type = 'single'">One-Time</li>
                                            <li :class="{'active' : charTabs === 'recurring'}" @click="charTabs = 'recurring'; filter.pricing_type = 'recurring'">Recurring</li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <select v-model="filter.product_id">
                                                    <option value="all">All Products</option>
                                                    <option v-for="(product, p) of products" :key="p" :value="product.id">{{ product.name }}</option>
                                                </select>
                                                <span><i class="fas fa-angle-down"></i></span>
                                            </li>
                                            <li v-click-outside="closeDurationType">
                                                <select v-model="filter.duration" @mousedown="customRange = false" @click="handleDurationType()">
                                                    <option :value="duration" v-for="(duration, d) of durations" :key="d">{{ duration }}</option>
                                                </select>
                                                <span><i class="fas fa-angle-down"></i></span>
                                                <div class="custom_range" :class="{ active: customRange }">
                                                    <h4>Custom Range</h4>
                                                    <ul>
                                                        <li>
                                                            <label for="">Start Date</label>
                                                            <datepicker v-model="filter.start_date" :max-date="maxStartDate" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                                        </li>
                                                        <li>
                                                            <label for="">End Date</label>
                                                            <datepicker v-model="filter.end_date" :min-date="minEndDate" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                                        </li>
                                                        <li>
                                                            <button type="button" class="btn save_btn" @click="refreshChart">Apply</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <h2 class="gross"><span>Gross Sales</span>{{ companyCurrencySymbol }}{{ parseFloat(grossSale).toFixed(2) }}</h2>
                                    <vue-echarts :option="option" style="height: 350px" ref="chart"></vue-echarts>
                                </div>
                                <div class="chart_card_list">
                                    <ul>
                                        <li>
                                            <chart-card title="Profit" :scores="chartStats.profit" :currency="companyCurrencySymbol" info="your gross salse minus refunds."/>
                                        </li>
                                        <li>
                                            <chart-card title="LTV" :scores="chartStats.ltv" :currency="companyCurrencySymbol" info="A daily snapshot of total gross sales divided by the number of unique customers, from the entire history of your account."/>
                                        </li>
                                        <li>
                                            <chart-card title="Refunds" :scores="chartStats.refunds" :currency="companyCurrencySymbol" info="A total amount of sales that have been refunded to your customers."/>
                                        </li>
                                        <li>
                                            <chart-card title="Orders" :scores="chartStats.orders" info="Number of successful orders placed."/>
                                        </li>
                                        <li>
                                            <chart-card title="Page Views" :scores="chartStats.views" info="Number of times your checkout pages  have been viewed. This is unique views."/>
                                        </li>
                                        <li>
                                            <chart-card title="Conversion" ending="%" :scores="chartStats.conversion" info="Page views divided by orders. Check your Sales By Product Report for conversion rates for specific products."/>
                                        </li>
                                        <li>
                                            <chart-card title="MRR" :scores="chartStats.mrr" :currency="companyCurrencySymbol" info="A daily snapshot of how much recurring revenue you are scheduled to collect in the next 30 days."/>
                                        </li>
                                        <li>
                                            <chart-card title="Active Subs" :scores="chartStats.subscribers" info="Number of active or trialing subscriptions in your OM checkout account."/>
                                        </li>
                                        <li>
                                            <chart-card title="Churn Rate" ending="%" :scores="chartStats.churn_rate" info="Number of cancelled subscription divided by the number of active subscriptions from 30 days ago."/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="right_sectn">
                                <div class="activities">
                                    <h3>
                                        Recent Activities
                                        <div class="filter">
                                            <select v-model="activityDuration">
                                                <option v-for="(activity, a) of activityFilter" :key="a" :value="activity.filter">{{ activity.title }}</option>
                                            </select>
                                            <span><i class="fas fa-angle-down"></i></span>
                                        </div>
                                    </h3>
                                    <div class="lifecycle">
                                        <ul>
                                            <li v-for="(activity, a) of activities" :key="a">
                                                <span class="icon"><i class="fa" :class="`${activity.status == 'failed' ? 'fa-times' : 'fa-check'}`"></i></span>
                                                <h4 class="capitalize">{{ activity.is_recurring ? 'recurring' : activity.status }}</h4>
                                                <div class="info">
                                                    <span>{{ companyCurrencySymbol }}{{ parseFloat(activity.price).toFixed(2) }} from <a :href="`mailto:${activity.email}`">{{ activity.email }}</a></span>
                                                    <span class="time">{{ moment(activity.created_at).fromNow() }}</span>
                                                </div>
                                            </li>
                                            <li v-if="activities.length == 0" class="blank">No recent activity found!</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <video-tutorial v-model="tutorial" :tutorial-video="tutorialVideo"></video-tutorial>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { VueEcharts } from 'vue3-echarts'
    import { mapState, mapActions } from 'vuex'

    const VideoTutorial = defineAsyncComponent(() => import('@/components/VideoTutorial'))
    const ChartCard = defineAsyncComponent(() => import('@/pages/checkout/components/ChartCard'))
    const SidebarComponent = defineAsyncComponent(() => import('@/pages/checkout/components/Sidebar'))

    import moment from 'moment'

    export default {
        name: 'Checkout Dashboard',

        data () {
            return {
                charTabs: 'total',
                activityDuration: 'today',
                activityFilter: [
                    { title: 'Today', filter: 'today' },
                    { title: 'Yesterday', filter: 'yesterday' },
                    { title: 'This week', filter: 'current_week' },
                ],
                moment,
                tutorial: false,
                tutorialVideo: 'https://www.loom.com/embed/49191fd0836043bdbefdd296543c2005?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true',
                filter: {
                    pricing_type: 'all',
                    product_id: 'all',
                    duration: 'Last 7 Days',
                    start_date: moment.utc().subtract(7, 'days').format('YYYY-MM-DD'),
                    end_date: moment.utc().format('YYYY-MM-DD'),
                },
                durations: [
                    'Today',
                    'Yesterday',
                    'Last 7 Days',
                    'Last 14 Days',
                    'Last 30 Days',
                    'This Month',
                    'Last Month',
                    'This Year',
                    'All Time',
                    'Custom Range',
                ],
                maxStartDate: moment().format('YYYY-MM-DD'),
                minEndDate: moment.utc().subtract(7, 'days').format('YYYY-MM-DD'),
                option : {
                    tooltip: {
                        trigger: 'axis',
                        formatter: (params) => params[0].name + ': $' + params[0].value
                    },
                    grid: {
                        left: 25,
                        right: '1%',
                        bottom: 15,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        offset: 10,
                        axisTick: {
                            length: 4,
                            lineStyle:{
                                type: "solid",
                                color: "#2F7FED",
                                cap: "round",
                                width: 4
                            }
                        },
                        axisLine:{
                            lineStyle:{
                                color: "#E8E7E7"
                            },
                            endLabel: {
                                show: true,
                                formatter: function (params) {
                                    return '$$' + params;
                                }
                            },
                        },
                        axisLabel: {
                            color: '#4D4D4D'
                        },
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    },
                    yAxis: {
                        type: 'value',
                        offset: 15,
                        minInterval: 1,
                        min: 0,
                        max: (value) => value.max < 5 ? 5 : value.max,
                        splitLine: {
                            show: false
                        }
                    },
                    series: [
                        {
                            symbol: 'none',
                            data: [70, 35, 85, 70, 224, 100, 90],
                            type: 'line',
                            smooth: true,
                            endLabel: {
                                show: true,
                                formatter: function (params) {
                                    return '$$' + params;
                                }
                            },
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: 'rgba(47,127,237,0.15)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(58,77,233,0)'
                                        }
                                    ],
                                    global: false
                                },
                            },
                            lineStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#2F7FED'
                                        },
                                        {
                                            offset: 1,
                                            color: '#88CBEE'
                                        }
                                    ],
                                    global: false
                                }
                            }
                        },
                    ],
                },
                customRange: false,
                mobile_filter: false,
                showSidebar: false,
            }
        },

        components: {
            ChartCard,
            VueEcharts,
            VideoTutorial,
            SidebarComponent,
        },

        watch: {
            activityDuration (filter) {
                const vm = this;

                vm.getRecentActivity(filter);
            },

            'filter.duration' (duration) {
                const vm = this;

                if (duration == 'Today') {
                    vm.filter.start_date  = moment.utc().format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                } else if (duration == 'Yesterday') {
                    vm.filter.start_date  = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
                } else if (duration == 'Last 7 Days') {
                    vm.filter.start_date  = moment.utc().subtract(6, 'days').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                } else if (duration == 'Last 14 Days') {
                    vm.filter.start_date  = moment.utc().subtract(13, 'days').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                } else if (duration == 'Last 30 Days') {
                    vm.filter.start_date  = moment.utc().subtract(29, 'days').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                } else if (duration == 'This Month') {
                    vm.filter.start_date  = moment.utc().startOf('month').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                } else if (duration == 'Last Month') {
                    vm.filter.start_date  = moment.utc().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
                } else if (duration == 'This Year') {
                    vm.filter.start_date  = moment.utc().startOf('year').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                } else if (duration == 'All Time') {
                    vm.filter.start_date  = '';
                    vm.filter.end_date    = '';
                }

                if (duration == 'Custom Range') {
                    vm.customRange = true;
                } else {
                    vm.customRange = false;
                }

                if (duration != 'Custom Range') {
                    vm.refreshChart();
                }
            },

            chartData () {
                const vm = this;

                vm.resetChart();
            },

            'filter.pricing_type' () {
                const vm = this;

                vm.refreshChart();
            },

            'filter.product_id' () {
                const vm = this;

                vm.refreshChart();
            },

            'filter.end_date' (endDate) {
                const vm = this;

                if (endDate) {
                    vm.maxStartDate = moment(endDate).format('YYYY-MM-DD');
                } else {
                    vm.maxStartDate = moment().format('YYYY-MM-DD');
                }
            },

            'filter.start_date' (startDate) {
                const vm = this;

                if (startDate) {
                    vm.minEndDate = moment(startDate).format('YYYY-MM-DD');
                } else {
                    vm.minEndDate = moment.utc().subtract(7, 'days').format('YYYY-MM-DD');
                }
            },
        },

        computed: mapState({
            chartData: state => state.checkoutModule.chartData,
            grossSale: state => state.checkoutModule.grossSale,
            products: state => state.checkoutModule.allProducts,
            chartStats: state => state.checkoutModule.chartStats,
            chartLoader: state => state.checkoutModule.chartLoader,
            activities: state => state.checkoutModule.recentActivities,
            recentActivitiesLoader: state => state.checkoutModule.recentActivitiesLoader,
            companyCurrencySymbol: state => state.checkoutModule.companyCurrencySymbol,
            user: state => state.authModule.user,
        }),

        mounted () {
            const vm = this;

            vm.getAllProducts();
            vm.resetChart();
            vm.getChartData(vm.filter);
            vm.getRecentActivity(vm.activityDuration);
        },

        methods: {
            ...mapActions({
                getChartData: 'checkoutModule/getChartData',
                getAllProducts: 'checkoutModule/getAllProducts',
                getRecentActivity: 'checkoutModule/getRecentActivity',
            }),

            refreshChart () {
                const vm = this;

                vm.filter.start_date  = moment(vm.filter.start_date).isValid() ? moment(vm.filter.start_date).format('YYYY-MM-DD') : '';
                vm.filter.end_date    = moment(vm.filter.start_date).isValid() ? moment(vm.filter.end_date).format('YYYY-MM-DD') : '';

                vm.customRange = false;
                vm.getChartData(vm.filter);
            },

            resetChart () {
                const vm = this;

                vm.option.xAxis.data = vm.chartData.axis;
                vm.option.series[0].data = vm.chartData.data;
                vm.option.yAxis.axisLabel =  {
                    color: '#4D4D4D',
                    formatter: (params) => vm.companyCurrencySymbol + params,
                };
                vm.option.tooltip =  {
                    trigger: 'axis',
                    formatter: (params) => params[0].name + ': $' + params[0].value
                };
                vm.$refs.chart.refreshChart();
            },

            handleDurationType () {
                const vm = this;

                if (vm.filter.duration == 'Custom Range') {
                    vm.customRange = true;
                }
            },

            closeDurationType () {
                const vm = this;

                vm.customRange = false
            },
        },
    }
</script>

<style scoped>
    .bar_menu {
        height: 18px;
        width: 18px;
        cursor: pointer;
        margin-right: 10px;
        display: none;
    }
    .container{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
    }
    .section_header{
        justify-content: flex-start;
    }
    .section_header .tutorial_btn{
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: auto;
    }
    .section_header .tutorial_btn label {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #2f7eed;
        cursor: pointer;
        min-width: auto;
    }
    .section_header .tutorial_btn img {
        max-height: 18px;
        width: auto;
        margin-left: 5px;
    }
    .video_player .modal_body {
        width: 100%;
        height: 400px;
        display: flex;
        padding: 15px;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    #checkout_index{
        align-items: flex-start;
    }
    .checkout-dashboard .panel_wpr, .checkout-dashboard .actions_widgets{
        margin-bottom: 0;
    }
    .checkout-dashboard .left_sectn, .checkout-dashboard .right_sectn{
        /* max-height: calc(100vh - 166px);
        overflow-y: scroll; */
        -ms-overflow-style: none;
        scrollbar-width: none;
        padding: 0 15px 15px 15px;
    }
    .checkout-dashboard .left_sectn::-webkit-scrollbar, .checkout-dashboard .right_sectn::-webkit-scrollbar{
        display: none;
    }
    .checkout-dashboard .right_sectn{
        position: sticky;
        top: 0;
    }
    .tabs{
        gap: 5px;
    }
    .tabs li{
        border: 0;
        background: #e9e9e9;
        padding: 7px 15px;
        border-radius: 3px;
        font-size: 13px;
        line-height: 19px;
        color: #5a5a5a;
        font-weight: 500;
        width: auto;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    .tabs li.active{
        background: #2f7eed;
        color: #fff;
    }
    .gross{
        font-size: 30px;
        line-height: 38px;
        font-weight: 400;
        color: #121525;
        margin-top: 30px;
        text-align: left;
    }
    .gross span{
        font-size: 13px;
        line-height: 17px;
        color: #5a5a5a;
        display: block;
        padding-bottom: 5px;
    }
    .widgets_wpr{
        height: auto;
    }
    .chart_card_list{
        margin: 30px 0 0 0;
    }
    .chart_card_list ul{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }
    .chart_card_list ul li{
        width: 33.333%;
        padding: 15px;
    }
    .right_sectn .activities{
        background: #fff;
        /* border: 1px solid #e0e0e0; */
        border-radius: 10px;
        box-shadow: 0px 2px 4px rgb(0,0,0,0.04);
    }
    .right_sectn .activities .filter{
        border: 1px solid #CDCDCD;
        border-radius: 3px;
        position: relative;
        line-height: 0;
    }
    .right_sectn .activities .filter select{
        width: 100px;
        height: 26px;
        padding: 0 25px 0 10px;
        background: transparent;
        -webkit-appearance: none;
        font-size: 11px;
    }
    .right_sectn .activities .filter span {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid rgba(205, 205, 205, 0.7);
        font-size: 13px;
        color: #767676;
        pointer-events: none;
    }
    .right_sectn .activities h3{
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e9e9e9;
    }
    .lifecycle{
        padding: 10px 20px;
    }
    .lifecycle ul{
        position: relative;
    }
    .lifecycle ul:before {
        content: '';
        position: absolute;
        top: 15px;
        bottom: 15px;
        left: 12px;
        width: 1px;
        background: #ccc;
    }
    .lifecycle ul li{
        width: 100%;
        padding: 15px 0 15px 40px;
        position: relative;
    }
    .lifecycle ul li.blank{
        padding: 15px 0;
        font-family: 'Architects Daughter', cursive;
        font-size: 16px;
        line-height: 26px;
        color: #a5a5a5;
        background: #fff;
    }
    .lifecycle ul li h4{
        font-size: 15px;
        line-height: 17px;
        font-weight: 500;
        color: #121525;
        display: flex;
        align-items: center;
    }
    .lifecycle ul li .info{
        padding: 10px 0;
        margin: 0;
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #32373b;
        text-align: left;
    }
    .lifecycle ul li .info span{
        display: block;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 5px;
    }
    .lifecycle ul li .info span a{
        text-decoration: none;
        color: #121525;
    }
    .lifecycle ul li .info span.time{
        font-weight: 400;
        color: #999;
    }
    .lifecycle ul li .icon{
        position: absolute;
        width: 25px;
        height: 25px;
        left: 0;
        top: 15px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border: 1px solid #bbbbbb;
        color: #5a5a5a;
        font-size: 11px;
    }
    @media(max-width: 1350px){
        .tabs li{
            font-size: 12px;
            padding: 7px 12px;
        }
        .right_sectn .activities h3{
            font-size: 15px;
        }
        .chart_card_list ul li{
            padding: 10px;
        }
    }
    @media(max-width: 1199px){
        .checkout-dashboard .left_sectn{
            max-height: none;
            margin-bottom: 30px;
        }
        .checkout-dashboard .right_sectn{
            margin-bottom: 30px;
        }
    }
    @media(max-width: 1199px){
        .bar_menu {
            display: block;
        }
    }
    @media(max-width: 599px){
        .chart_card_list ul li{
            width: 50%;
        }
    }
    @media(max-width: 420px){
        .chart_card_list ul li{
            width: 100%;
        }
    }
</style>
